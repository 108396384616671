@value colors: "./colors.module.css";
@value primary, grey400 from colors;

.tableRow {
  display: flex;
  background-color: grey400;
}
.tableRowTransparent {
  display: flex;
  background-color: transparent;
}
.tableCell {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
}
.firstColTableCell {
  composes: tableCell;
  justify-content: flex-start;
}
.cellText {
  composes: smallText from './typography.module.css';
}
.tableHeader {
  composes: cellText;
  font-weight: 700;
  color: primary;
}
