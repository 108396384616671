@value colors: "./colors.module.css";
@value primary, grey400 from colors;

.stepWrapper {
  margin-bottom: 16px;
}
.stepImgContainer {
  margin-top: 10px;
  margin-left: 34px;
  display: flex;
}
.stepImgWrapper {
  position: relative;
  overflow: hidden;
  width: 160px;
  height: 90px;
  background-color: grey400;
  margin-right: 10px;
}
.stepImg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.stepNumber {
  composes: heading2 from './typography.module.css';
  display: inline-block;
  margin-right: 8px;
  width: 26px;
  text-align: center;
  color: primary;
}
.stepTitle {
  composes: subtitle from './typography.module.css';
  display: inline-block;
  color: primary;
}
.bullet {
  margin: 0 16px 0 10px;
}
.dot {
  composes: text from './typography.module.css';
  color: primary;
}
.description {
  composes: text from './typography.module.css';
}
