@value colors: "./colors.module.css";
@value primary from colors;

.dotWrapper {
  margin: 0 18px 0 10px;
}
.dot {
  composes: text from './typography.module.css';
  color: primary;
}
.itemText {
  composes: text from './typography.module.css';
}
