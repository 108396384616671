.container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.noMarginContainer {
  composes: container;
  margin-bottom: 0;
}
.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.subtitle {
  composes: subtitle from './typography.module.css';
}
