@value colors: "./colors.module.css";
@value primary, primaryLight, secondary, default from colors;

.illustrationWrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 5;
  background-color: primaryLight;
  padding: 26px;
}
.illustration {
  z-index: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.generalWrapper {
  flex: 4;
  padding: 22px 26px;
}
.countryWrapper {
  position: relative;
  display: block;
  background-color: rgba(0, 91, 147, 0.75);
  padding: 10px 22px;
  margin-bottom: 8px;
}
.countryTitle {
  composes: heading2 from './typography.module.css';
  color: #fff;
}
.typeWrapper {
  position: relative;
  display: block;
  background-color: rgba(47, 53, 66, 0.9);
  padding: 6px 22px;
}
.typeTitle {
  composes: heading3 from './typography.module.css';
  color: #fff;
}
.block {
  margin-bottom: 26px;
}
.recipeName {
  composes: heading1 from './typography.module.css';
}
.originalName {
  composes: heading2 from './typography.module.css';
  display: inline-block;
  color: default;
}
.tag {
  margin-right: 20px;
}
.ownStuffWrapper {
  display: flex;
}
.ownStuffBlock {
  width: 50%;
}
.description {
  composes: text from './typography.module.css';
}
.ingredientsExtraInfo {
  composes: subtitle from './typography.module.css';
  text-transform: none;
  color: default;
  margin-left: 16px;
}
.ingredientsExtraInfo::before {
  content: '●';
  color: primary;
  margin-right: 16px;
}
