@value colors: "./colors.module.css";
@value primary from colors;

.container {
  position: relative;
  width: 1169px;
  height: 827px;
}
.logoWrapper {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.footerWrapper {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 13px;
  display: flex;
}
.blankSpace {
  flex: 1;
}
.footer {
  flex: 3;
  margin-left: 54px;
  display: flex;
  align-items: center;
}
.socialText {
  composes: text from './typography.module.css';
}
.highlight {
  color: primary;
  font-weight: 700;
}
.socialIcon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
