@value colors: "./colors.module.css";
@value grey100 from colors;

.preparationWrapper {
  flex: 3;
  padding: 26px;
}
.extrasWrapper {
  background-color: grey100;
  flex: 1;
  padding: 26px 22px;
}
.block {
  margin-bottom: 26px;
}
.goodStart {
  composes: text from './typography.module.css';
}
