.ingredientWrapper {
  display: flex;
  flex-direction: column;
  width: 25%;
  text-align: center;
  margin: 10px 0;
}
.imgWrapper {
  position: relative;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: auto;
  margin-bottom: 8px;
}
.img {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.itemLabel {
  composes: smallText from './typography.module.css';
  white-space: nowrap;
}
