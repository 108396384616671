@value colors: "./colors.module.css";
@value default, defaultDark from colors;

.baseHeading {
  font-family: 'Stolzl';
  text-transform: uppercase;
  color: defaultDark;
}

.heading1 {
  composes: baseHeading;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 36px;
}
.heading2 {
  composes: baseHeading;
  line-height: 1;
  font-weight: 700;
  font-size: 26px;
}
.heading3 {
  composes: baseHeading;
  font-weight: 400;
  font-size: 20px;
}
.subtitle {
  composes: baseHeading;
  font-weight: 500;
  font-size: 16px;
}
.text {
  font-family: 'OpenSans';
  color: default;
  font-size: 13px;
  margin: 0;
}
.smallText {
  composes: text;
  font-size: 12px;
}
